/* eslint-disable consistent-return */
import type { WorkspaceType } from 'graphql/__generated__/graphql';
import type { WorkspaceSettings } from 'constants/workspaceSettings';

export const getWorkspaceAvatarName = (name: string) => {
  if (!name) return;

  const words = name.split(' ');

  if (words.length > 1 && words[1]) {
    return words[0].charAt(0) + words[1].charAt(0);
  }

  return name.charAt(0) + name.charAt(1);
};

export const getWorkspaceSetting = (key: string, settings: WorkspaceSettings): boolean => {
  if (settings[key] === undefined) {
    throw new Error(`Key ${key} not found in workspace settings ${JSON.stringify(settings)}`);
  }

  return settings[key];
};

export const getWorkspaceOption = (workspace: WorkspaceType) => (
  { value: workspace.id, label: workspace.slug }
);

export const workspacesToOptions = (workspaces: WorkspaceType[]) => (
  Object.values(workspaces).map(getWorkspaceOption)
);

export const getWorkspaceSubdomain = (): unknown => {
  const { hostname } = window.location;
  const hostnameParts = hostname.split('.');
  let subdomain = null;

  if (hostnameParts.length === 3) {
    [subdomain] = hostnameParts;
  } else if (hostnameParts.length === 4) {
    [, subdomain] = hostnameParts;
  }

  return subdomain;
};

export const getWorkspaceRedirectBaseUrl = (workspace: WorkspaceType) => {
  const { hostname, protocol } = window.location;
  const hostnameParts = hostname.split('.');

  if (hostnameParts.length === 3) {
    hostnameParts[0] = workspace ? workspace.slug : 'app';
  } else if (hostnameParts.length === 4) {
    hostnameParts[1] = workspace ? workspace.slug : 'app';
  }

  return `${protocol}//${hostnameParts.join('.')}`;
};

export const isWorkspace404 = (workspace: WorkspaceType) => {
  const subdomain = getWorkspaceSubdomain();

  return workspace === null && subdomain !== 'app';
};
